$(() => {
    $('.search-result__item').each(function (index) {

        var count = ($(this).html().match(/fsHit/g) || []).length;
        var i = 0;

        while (i < (count + 1)) {

            $(this).html($(this).html().replace(/&lt;span class="fsHit"&gt;(.*?)&lt;\/span&gt;/, '<span class="fsHit">$1</span>'));
            
            i++;
        }
    });

    var searchBaselineUrl = $(".search-form").attr("action");

    $(".search-form").on("change", function (e) {
        e.preventDefault();
        var q = $(".search-input").val();
        var searchUrl = searchBaselineUrl + "?q=" + q;
        $(".search-form").attr("action", searchUrl);
        $(this).submit();
    });
});